import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';

import { catchError, Observable, throwError } from 'rxjs';
import { AccountLink } from 'src/app/pagos/models/accountLinks.model';
import { StripeAccount } from '@models/stripeAccount.model';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { selectClientToken } from '@auth-module/store/auth.selectors';

@Injectable({
  providedIn: 'root',
})
export class PaymentsService {
  //test url
  // url ="http://localhost:5001/contactame-app/us-central1/apipagos";

  url = 'https://us-central1-contactame-app.cloudfunctions.net/apipagos';
  token = '';
  constructor(private store: Store, private http: HttpClient) {
    this.store.select(selectClientToken).subscribe((token: string) => {
      this.token = token;
    });
  }

  crateAccount(): Observable<any> {
    return this.http.post<any>(`${this.url}/account/create`, {
      email: 'test@email.com',
      typeAccount: 0,
    });
  }
  signUp(accountId: string, accountName: string, setup: boolean): Observable<AccountLink> {
    return this.http.post<AccountLink>(`${this.url}/account/signup`, {
      accountId: accountId,
      name: accountName,
      setup: setup,
    });
  }

  resumeAccount(accountId: string): Observable<StripeAccount> {
    return this.http.post<StripeAccount>(`${this.url}/account/resume`, { accountId: accountId });
  }
  editAccount(accountId: string, nameAccount: string): Observable<any> {
    return this.http.post<any>(`${this.url}/account/edit`, {
      accountId: accountId,
      name: nameAccount,
    });
  }
  configBankData(accountId: string): Observable<any> {
    return this.http.post<any>(`${this.url}/account/bank`, { accountId: accountId });
  }
  getBalance(accountId: string): Observable<any> {
    return this.http.post<any>(`${this.url}/account/balance`, { accountId: accountId });
  }

  verifyAccount(accountId: string, nameAccount: string): Observable<any> {
    return this.http.post<any>(`${this.url}/account/verify`, {
      accountId: accountId,
      name: nameAccount,
    });
  }

  deleteAccount(accountId: string): Observable<any> {
    return this.http.post<any>(`${this.url}/account/delete`, { accountId: accountId });
  }

  lastTransaccion(accountId: string): Observable<any> {
    return this.http.post<any>(`${this.url}/account/last`, { accountId: accountId });
  }
  payout(accountId: string, amount: string, currency: string): Observable<any> {
    return this.http.post<any>(`${this.url}/account/payout`, {
      accountId: accountId,
      amount: amount,
      currency: currency,
    });
  }
  createToken(name: string, type: string, routing: string, account: string): Observable<any> {
    return this.http.post<any>(`${this.url}/account/token`, {
      name: name,
      type: type,
      routing: routing,
      account: account,
    });
  }
  updateAccount(accountId: string, bkToken: string): Observable<any> {
    return this.http.post<any>(`${this.url}/account/update`, {
      accountId: accountId,
      bkToken: bkToken,
    });
  }
  createPaymentDonation({total, currency }: any, metadata: object) {
    const headers = new HttpHeaders()
      .set('Authorization', 'Bearer ' + this.token)
      .set('x-api-key', environment.apiKeyPay);

    return this.http
      .post(
        environment.urlPayments + '/stripe/crearPago',
        {
          amount: total,
          currency: currency,
          metadata,
        },
        { headers },
      )
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError(({ error }) => {
          return throwError(() => error?.message);
        }),
      );
  }
  payDonation(
    intentId: string,
    { nameCard, numberCard, expireMonth, expireYear, codeCard, total }: any,
    
  ) {
    const headers = new HttpHeaders()
      .set('Authorization', 'Bearer ' + this.token)
      .set('x-api-key', environment.apiKeyPay);

    return this.http
      .post(
        environment.urlPayments + '/stripe/pagar',
        {
          intentId,
          nameCard,
          numberCard,
          expireMonth,
          expireYear,
          codeCard,
          total,
        },
        { headers },
      )
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError(({ error }) => {
          return throwError(() => error?.message);
        }),
      );
  }
  createPayment(amount: number, currency: string, metadata: object) {
    const headers = new HttpHeaders()
      .set('Authorization', 'Bearer ' + this.token)
      .set('x-api-key', environment.apiKeyPay);

    return this.http
      .post(
        environment.urlPayments + '/stripe/crearPago',
        {
          amount,
          currency,
          metadata,
        },
        { headers },
      )
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError(({ error }) => {
          return throwError(() => error?.message);
        }),
      );
  }

  pay(
    intentId: string,
    { nameCard, numberCard, expireMonth, expireYear, codeCard }: any,
    total: number,
  ) {
    const headers = new HttpHeaders()
      .set('Authorization', 'Bearer ' + this.token)
      .set('x-api-key', environment.apiKeyPay);

    return this.http
      .post(
        environment.urlPayments + '/stripe/pagar',
        {
          intentId,
          nameCard,
          numberCard,
          expireMonth,
          expireYear,
          codeCard,
          total,
        },
        { headers },
      )
      .pipe(
        map((resp: any) => {
          return resp;
        }),
        catchError(({ error }) => {
          return throwError(() => error?.message);
        }),
      );
  }
}
