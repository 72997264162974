import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { AppState } from '@core/store.state';
import { EMPTY, exhaustMap, of, throwError, withLatestFrom } from 'rxjs';
import { emptyAction } from '@settings-module/store/settings.actions';
import { selectClient, selectClientSubscription } from '@client-module/store/client.selectors';
import { PerfilService } from '@services/perfil.service';
import { catchError, map } from 'rxjs/operators';
import { selectClientToken, selectPublicIp } from '@auth-module/store/auth.selectors';
import {
  checkIpbeforeCreateService,
  chooseActiveProfile,
  createProfileLoad,
  createService,
  deleteProfileLoad,
  deleteProfileSuccess,
  setShowModalOfDeleteProfile,
  setShowModalOfProfiles,
  updateProfileSuccess,
} from './profile.actions';
import { Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { selectAbleToCreateProfile } from './profile.selectors';
import { ClienteService } from '@core/services/cliente.service';
import { countryList } from '@core/helpers/core.helpers';
import { ServiciosService } from '@core/services/servicios.service';
import { AlertsService } from '@core/services/alerts.service';
import { Mbresult } from '@core/models/auth.model';
import { PaymentsService } from '@core/services/payments.service';
import { payDonationError, payDonationLoad, payDonationSuccess } from '@client-module/store/client.actions';

@Injectable()
export class ProfileEffects {
  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private profileService: PerfilService,
    private clientService: ClienteService,
    private alertServ: AlertsService,
    private servSer: ServiciosService,
    private paymentsService: PaymentsService,
    private router: Router,
    private translocoService: TranslocoService,
  ) {}

  // DOCS: Initial effect to update the client information
  initializePneSignal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(chooseActiveProfile),
      withLatestFrom(this.store.select(selectClient), this.store.select(selectClientToken)),
      // DOCS: Initialize the OneSignal SDK
      exhaustMap(([{ profile }, client, token]) => {
        // DOCS: Si no esta definido el cliente significa que es básico
        if (!client.tipoSuscripcion) {
          this.router.navigate(['/plans/pro']);
          return of(emptyAction());
        }
        return this.profileService.updatePerfilActivo(profile._id, token).pipe(
          map(() => {
            // FIXME: El mbPerfil viene null por eso se envía el que viene de inicio, pero no lo esta actualizando en el state
            this.store.dispatch(setShowModalOfProfiles({ showModalOfProfiles: false }));
            return updateProfileSuccess({ profile });
          }),
        );
      }),
    ),
  );
  // DOCS: Delete profile
  deleteProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteProfileLoad),
      withLatestFrom(this.store.select(selectClientToken)),
      exhaustMap(([{ profile }, token]) => {
        return this.profileService.eliminarPerfil(profile._id, token).pipe(
          map(() => {
            this.store.dispatch(setShowModalOfDeleteProfile({ showModalOfDeleteProfile: false }));
            this.store.dispatch(setShowModalOfProfiles({ showModalOfProfiles: false }));
            return deleteProfileSuccess({ profile });
          }),
        );
      }),
    ),
  );

  // DOCS: Create profile load
  createProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createProfileLoad),
      withLatestFrom(
        this.store.select(selectClientSubscription),
        this.store.select(selectAbleToCreateProfile),
      ),
      exhaustMap(([, subscription, ableToCreateProfile]) => {
        if (ableToCreateProfile) {
          this.router.navigate([this.translocoService.translate('routing.crear')]);
        } else {
          if (subscription?.includes('pro')) {
            this.router.navigate(['/plans/premium']);
          } else if (!subscription) {
            this.router.navigate(['/plans/pro']);
          }
        }
        return of(emptyAction());
      }),
    ),
  );

  // DOCS: verifica que la ip sea del país correspondiente
  verifyIpBeforeCreateService$ = createEffect(() =>
    this.actions$.pipe(
      ofType(checkIpbeforeCreateService),
      withLatestFrom(this.store.select(selectPublicIp), this.store.select(selectClientToken)),

      exhaustMap(([action, ip, token]) => {

        //DOCS: se deshabilita funcionalidad de verificar pais por mal funcionamiento
        // DOCS: verifico ip con el ep mbclienteObtenerInfoPorIp
        // return this.clientService.obtenerIpCliente(ip, token).pipe(
        //   map((resp) => {
        //     const messages = this.translocoService.translateObject('addServ.alert');
            
            
        //     if (resp.errors) {
        //       this.alertServ.sendMsj('danger', messages.error);
        //       return emptyAction();
        //     }
            
        //     const country = resp.data.mbclienteObtenerInfoPorIp.infoGeoIp.pais;
        //     if (countryList.includes(country)) {
        //       return createService({ service: action.service });
        //     } else {
        //       this.alertServ.sendMsj('danger', messages.error);
        //       return emptyAction();
        //     }
           

        //   }),
        // );

        return of(createService({ service: action.service }));

      }),
      catchError((error) => {
        const messages = this.translocoService.translateObject('addServ.alert');
        this.alertServ.sendMsj('danger', messages.error);
        return of(emptyAction());
      }),
    ),
  );

  // DOCS: Se crea el servicio
  createService$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createService),
      withLatestFrom(this.store.select(selectClientToken)),

      exhaustMap(([action, token]) => {
        // DOCS: verifico ip con el ep mbclienteObtenerInfoPorIp
        const messages = this.translocoService.translateObject('addServ.alert');
        return this.servSer.agregarServicio(action.service, token).pipe(
          map((resp) => {
            const mbResult: Mbresult = resp.data.createMbservicio;

            if (mbResult.success) {
              setTimeout(() => {
                this.alertServ.sendMsj('success', messages.successCreate);
              }, 1200);
              this.router.navigate(['/services']);
            } else {
              this.alertServ.sendMsj('danger', messages.error);
            }
            return emptyAction();
          }),
        );
      }),
      catchError((error) => {
        const messages = this.translocoService.translateObject('addServ.alert');
        this.alertServ.sendMsj('danger', messages.error);
        return of(emptyAction());
      }),
    ),
  );

//DOCS: SE PAGA DONACION
  paymentVerify$ = createEffect(() =>
    this.actions$.pipe(
      ofType(payDonationLoad),
      exhaustMap(({ cardInfo, metadata }) => {
        return this.paymentsService.createPaymentDonation(cardInfo, metadata).pipe(
          map((resp) => {
            const messages = this.translocoService.translateObject('payment.messages');
            if (resp.success) {
              return { cardInfo, mbPaymentIntent: resp.mbPaymentIntent };
            }
            this.alertServ.sendMsj('danger', messages.error);
            return {};
          }),
        );
      }),
      exhaustMap(({ cardInfo, mbPaymentIntent }) => {
        if (!cardInfo) {
          return EMPTY;
        }
        return this.paymentsService.payDonation(mbPaymentIntent.id, cardInfo).pipe(
          map((resp2) => {
            const messages = this.translocoService.translateObject('payment.messages');
            if (resp2.success) {
              this.router.navigate(['account/edit']);
              this.alertServ.sendMsj('success', messages.success);
              return payDonationSuccess();
            }
            this.alertServ.sendMsj('danger', messages.error);
            return payDonationError();
          }),
        );
      }),
      catchError(({ error }) => {
        const messages = this.translocoService.translateObject('payment.messages');
        this.alertServ.sendMsj('danger', messages.error);
        return throwError(() => error?.message);
      }),
    ),
  );
}
