import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TosComponent } from './tos/tos.component';
import { TagComponent } from './tag/tag.component';
import { BookingComponent } from '@shared/booking/booking.component';
import { AppointmentsComponent } from './appointments/appointments.component';
import { ReserveOkComponent } from './pages/reserve-ok/reserve-ok.component';
import { ReserveCancelComponent } from './pages/reserve-cancel/reserve-cancel.component';
import { ReserveTimeComponent } from './pages/reserve-time/reserve-time.component';
import { loginGuard } from '@guards/login.guard';
import { protect2Guard } from '@guards/protec2.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'profile',
    pathMatch: 'full',
  },
  {
    path: 'scan',
    component: TagComponent,
  },
  {
    path: 'cita/:id',
    component: AppointmentsComponent,
    canMatch: [protect2Guard],
  },
  {
    path: 'appointment/:id',
    component: AppointmentsComponent,
    canMatch: [protect2Guard],
  },
  {
    path: 'cita',
    component: BookingComponent,
  },
  {
    path: 'reserve-ok',
    component: ReserveOkComponent,
  },
  {
    path: 'reserve-cancel',
    component: ReserveCancelComponent,
  },
  {
    path: 'reserve-time',
    component: ReserveTimeComponent,
  },
  {
    path: 'terminos/terminos-de-servicio',
    loadChildren: () => import('./tos/tos.module').then((m) => m.TosModule),
  },
  {
    path: 'terminos/politica-de-privacidad',
    loadChildren: () =>
      import('./privacy-policy/privacy-policy.module').then((m) => m.PrivacyPolicyModule),
  },
  {
    path: 'doc/terms-conditions',
    loadChildren: () => import('./tos/tos.module').then((m) => m.TosModule),
  },
  {
    path: 'doc/privacy-policy',
    loadChildren: () =>
      import('./privacy-policy/privacy-policy.module').then((m) => m.PrivacyPolicyModule),
  },
  {
    path: 'profile',
    loadChildren: () =>
      import('./user-profile/user-profile.module').then((m) => m.UserProfileModule),
  },
  {
    path: 'perfil',
    loadChildren: () =>
      import('./user-profile/user-profile.module').then((m) => m.UserProfileModule),
  },
  {
    path: 'login',
    loadChildren: () => import('./auth/login/auth.module').then((m) => m.AuthModule),
    canMatch: [loginGuard],
  },
  {
    path: 'view',
    loadChildren: () => import('./auth/login/auth.module').then((m) => m.AuthModule),
    canMatch: [loginGuard],
  },
  {
    path: 'forgot',
    loadChildren: () =>
      import('./auth/forgot-password/forgot-password.module').then((m) => m.ForgotPasswordModule),
  },
  { path: 'buy', loadChildren: () => import('./buy/buy.module').then((m) => m.BuyModule) },
  {
    path: 'payments',
    loadChildren: () => import('./pagos/pagos.module').then((m) => m.PagosModule),
    canMatch: [protect2Guard],
  },
  {
    path: 'plans',
    loadChildren: () => import('./planes/planes.module').then((m) => m.PlanesModule),
    canMatch: [protect2Guard],
  },
  {
    path: 'services',
    loadChildren: () => import('./service/service.module').then((m) => m.ServiceModule),
    canMatch: [protect2Guard],
  },
  {
    path: 'main-search',
    loadChildren: () => import('./mod-search/mod-search.module').then((m) => m.ModSearchModule),
    canMatch: [protect2Guard],
  },
  {
    path: 'register',
    loadChildren: () =>
      import('./auth/register-container/register-container.module').then(
        (m) => m.RegisterContainerModule,
      ),
  },
  {
    path: 'cuenta',
    loadChildren: () => import('./cuenta/cuenta.module').then((m) => m.CuentaModule),
    canMatch: [protect2Guard],
  },
  {
    path: 'account',
    loadChildren: () => import('./cuenta/cuenta.module').then((m) => m.CuentaModule),
    canMatch: [protect2Guard],
  },
  {
    path: 'reservas',
    loadChildren: () => import('./reservas/reservas.module').then((m) => m.ReservasModule),
    canMatch: [protect2Guard],
  },
  {
    path: 'bookings',
    loadChildren: () => import('./reservas/reservas.module').then((m) => m.ReservasModule),
    canMatch: [protect2Guard],
  },
  {
    path: 'history',
    loadChildren: () => import('./historial/historial.module').then((m) => m.HistorialModule),
    canMatch: [protect2Guard],
  },
  {
    path: 'benefits',
    loadChildren: () => import('./benefits/benefits.module').then((m) => m.BenefitsModule),
    canMatch: [protect2Guard],
  },
  {
    path: 'chat',
    loadChildren: () => import('@chat-module/components/chat/chat.module').then((m) => m.ChatModule),
    canMatch: [protect2Guard],
  },
  {
    path: '**',
    loadChildren: () =>
      import('./user-profile/user-profile.module').then((m) => m.UserProfileModule),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      // initialNavigation: 'enabled',
      useHash: true,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
