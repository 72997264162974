import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { MbCliente } from '../models/mbCliente.model';
import { MbCatalogo } from '@models/transaction.model';
import { map } from 'rxjs/operators';
import { BaseService } from '@services/base.service';
import { CreateMbclienteempresaInput } from '@models/create-mb-cliente-empresa.model';
import {
  CreateMbclienteInput,
  Mbcliente,
  Mbresult,
  UpdateMbclienteInput,
} from '@models/auth.model';
import { Store } from '@ngrx/store';
import { selectClientToken } from '@auth-module/store/auth.selectors';
import { isEmpty } from 'lodash-es';
import { ApiResponse } from '@models/api.models';

@Injectable({
  providedIn: 'root',
})
export class ClienteService extends BaseService {
  token = '';
  constructor(private store: Store, http: HttpClient) {
    super(http);
    this.store.select(selectClientToken).subscribe((token: string) => {
      this.token = token;
    });
  }

  leerCliente(idCliente: string, token: string): Observable<any> {
    const headers = new HttpHeaders()
      .set('Authorization', 'Bearer ' + token)
      .set('x-api-key', environment.apiKey);
    const query = `query mbcliente($id:ID!){
  mbcliente(id:$id){
    success
    message
    mbCliente{

      _id
      nombres
      apellidos
      imagen
      correo
      telefono
      pais
      estadoPais
      ciudad
      direccion
      total
    }
  }
}`;

    const variables = {
      id: idCliente,
    };

    return this.http.post(environment.urlGraphql, { query, variables }, { headers });
  }
  getClient(idCliente: string, token: string): Observable<any> {
    const headers = new HttpHeaders()
      .set('Authorization', 'Bearer ' + token)
      .set('x-api-key', environment.apiKey);
    const query = `query mbcliente($id:ID!){
  mbcliente(id:$id){
    success
    message
    mbCliente{
      _id
      apellidos
      calificacion
      cantidadNoLeido
      ciudad
      codigo
      codigoPostal
      codigoReferido
      correo
      direccion
      einNumber
      estadoPais
      fechaCreacionEmpresa
      fechaHoraSuscripcion
      fechaNacimiento
      idAccountStripe
      identificacion
      idGoogle
      imagen
      listaMbPerfil {
        _id
        altura
        calificacion
        ciudad
        codigoPostal
        correo
        direccion
        edad
        esActivo
        esPublico
        estadoPais
        experiencia
        nombre
        pais
        tema
      }
      llaveFactorAutenticacion
      nombres
      pais
      planSeleccionado
      puntos
      telefono
      token
      listaTokenFirebase
      mbTransaccionSuscripcion {
        _id
        transaccion
      }
      tipoSuscripcion
      zonaHoraria
      mbTipoCuenta{
        _id
        catalogo
      }
      tipoMetodoAuthenticate
      usuarioAccesoAuthenticate
      esValidoAuthenticate
      respuestaAuthenticate
      intentoProcesoAuthenticate
      mbRepresentante{
        tipoMetodoAuthenticate
        usuarioAccesoAuthenticate
        esValidoAuthenticate
        respuestaAuthenticate
        intentoProcesoAuthenticate
        inicioProcesoAuthenticate
        _id
        apellidos
        nombres
      }
    }
  }
}`;

    const variables = {
      id: idCliente,
    };

    return this.http.post(environment.urlGraphql, { query, variables }, { headers });
  }

  updateCliente(Cliente: MbCliente | UpdateMbclienteInput): Observable<any> {
    const headers = new HttpHeaders()
      .set('Authorization', 'Bearer ' + this.token)
      .set('x-api-key', environment.apiKey);
    const query = `mutation updateMbcliente($updateMbclienteInput:UpdateMbclienteInput!){
      updateMbcliente(updateMbclienteInput:$updateMbclienteInput){
        message
        success
        mbCliente {
          _id
          mbRepresentante{
            tipoMetodoAuthenticate
            usuarioAccesoAuthenticate
            esValidoAuthenticate
            respuestaAuthenticate
            intentoProcesoAuthenticate
            inicioProcesoAuthenticate
            _id
            apellidos
            codigo
            estadoPais
            fechaNacimiento
            idGoogle
            identificacion
            imagen
            mbTipoCuenta {
              _id
            }
            nombres
            pais
          }
        }
      }
    }`;
    // TODO: Agregar el correo ya que al devolver nullo ahorita el servicio truena

    const variables = {
      updateMbclienteInput: {
        ...Cliente,
      },
    };

    return this.http.post(environment.urlGraphql, { query, variables }, { headers });
  }
  updateTokenFirebase(Cliente: UpdateMbclienteInput): Observable<any> {
    const headers = new HttpHeaders()
      .set('Authorization', 'Bearer ' + this.token)
      .set('x-api-key', environment.apiKey);
    const query = `mutation agregarTokenFirebaseMbcliente($id: ID! $tokenFirebase: String!){
  agregarTokenFirebaseMbcliente(id:$id	tokenFirebase:$tokenFirebase){
    message
    success
        
  }
}`;
    // TODO: Agregar el correo ya que al devolver nullo ahorita el servicio truena

    const variables = {
      
        ...Cliente,
      
    };

    return this.http.post(environment.urlGraphql, { query, variables }, { headers });
  }
  removeTokenFirebase(Cliente: UpdateMbclienteInput): Observable<any> {
    const headers = new HttpHeaders()
      .set('Authorization', 'Bearer ' + this.token)
      .set('x-api-key', environment.apiKey);
    const query = `mutation eliminarTokenFirebaseMbcliente($id: ID! $tokenFirebase: String!){
  
  eliminarTokenFirebaseMbcliente(id:$id tokenFirebase:$tokenFirebase){
    message
    success
    
  }
}`;
    // TODO: Agregar el correo ya que al devolver nullo ahorita el servicio truena

    const variables = {

      ...Cliente,

    };

    return this.http.post(environment.urlGraphql, { query, variables }, { headers });
  }
  subirImagen(file: Blob, token: string): Observable<any> {
    const headers = new HttpHeaders()
      .set('Authorization', 'Bearer ' + token)
      .set('x-api-key', environment.apiKey);
    const url = environment.urlFileUpload;

    const formulario = new FormData();

    formulario.append('bucketName', 'januus/perfil');
    formulario.append('upload', file);

    return this.http.post(url, formulario, { headers });
  }
  solicitarDineroCliente(idCliente: string, monto: number, token: string): Observable<any> {
    const headers = new HttpHeaders()
      .set('Authorization', 'Bearer ' + token)
      .set('x-api-key', environment.apiKey);
    const query = `mutation solicitudRetiroMbcliente($id:ID! $monto:Float!){
    solicitudRetiroMbcliente(id:$id monto:$monto){
      success
      message
    }
  }`;

    const variables = {
      id: idCliente,
      monto: monto,
    };

    return this.http.post(environment.urlGraphql, { query, variables }, { headers });
  }

  createMbCliente(createMbclienteInput: Mbcliente | CreateMbclienteInput): Observable<any> {
    const headers: HttpHeaders = new HttpHeaders().set('x-api-key', environment.apiKey);
    const query = `mutation createMbcliente($createMbclienteInput: CreateMbclienteInput!) {
      createMbcliente(createMbclienteInput: $createMbclienteInput) {
        success
        message
        mbCliente {
          _id
          apellidos
          calificacion
          ciudad
          clave
          codigo
          codigoPostal
          codigoReferido
          correo
          direccion
          einNumber
          estadoPais
          fechaCreacionEmpresa
          fechaHoraSuscripcion
          imagen
          llaveFactorAutenticacion
          nombres
          pais
          planSeleccionado
          telefono
          token
          total
          mbTipoCuenta{
            _id
            catalogo
          }
          tipoSuscripcion
          zonaHoraria
          tipoMetodoAuthenticate
          usuarioAccesoAuthenticate
          esValidoAuthenticate
          respuestaAuthenticate
          intentoProcesoAuthenticate
        }
      }
    }`;
    const variables = {
      createMbclienteInput,
    };

    return this.http.post(this.apiUrl, { query, variables }, { headers }).pipe(
      map((resp: ApiResponse) => {
        if (resp.errors && !isEmpty(resp.errors)) {
          const error = resp.errors[0].message;
          console.error(error);
          throwError(() => error);
          return null;
        }
        return resp.data;
      }),
      catchError((error) => {
        console.error(error);
        return throwError(() => error);
      }),
    );
  }

  createMbClienteEmpresa(
    createMbclienteempresaInput: CreateMbclienteempresaInput,
    lang: string = 'en',
  ): Observable<Mbresult> {
    const headers: HttpHeaders = new HttpHeaders()
      .set('x-api-key', environment.apiKey)
      .set('idioma', lang);
    const query = `mutation createMbclienteempresa($createMbclienteempresaInput: CreateMbclienteempresaInput!) {
      createMbclienteempresa(createMbclienteempresaInput: $createMbclienteempresaInput) {
        success
        message
        mbPerfil {
          _id
          altura
          calificacion
          ciudad
          codigoPostal
          correo
          direccion
          edad
          esPublico
          estadoPais
          experiencia
          fechaNacimiento
          imagen
          latitud
          listaDocumento {
            descripcion
            listaArchivo
            titulo
          }
          listaEnlace
          listaExperienciaLaboral {
            actividad
            cargo
            empresa
            esActual
            fechaDesde
            fechaHasta
          }
          listaInfoIdioma {
            idioma
            profesion
            descripcion
            listaEtiqueta
            mbIndustria{
              _id
              catalogo
            }
          }
          longitud
          nombre
          pais
          peso
          telefono
          tema
          unidadMedidaAltura
          unidadMedidaPeso
          usuario
          verCorreo
          verDireccion
          verAltura
          verCiudad
          verDescripcion
          verEdad
          verPais
          verPeso
          verFoto
          verTelefono
          verWhatsapp
          whatsapp
          zonaHoraria
        }
        mbEmpresa {
          _id
          stripeSuscripcionAnualPremium
          stripeSuscripcionMensualPremium
          stripeSuscripcionMensualPro
          versionApp
          versionSitio
        }
        mbCliente {
          _id
          apellidos
          calificacion
          ciudad
          clave
          codigo
          codigoPostal
          codigoReferido
          correo
          direccion
          einNumber
          estadoPais
          fechaCreacionEmpresa
          fechaHoraSuscripcion
          createMbclienteempresa
          cantidadNoLeido
          imagen
          listaMbPerfil {
            _id
            altura
            calificacion
            ciudad
            codigoPostal
            correo
            direccion
            edad
            esPublico
            estadoPais
            experiencia
            fechaNacimiento
            imagen
            latitud
            listaDocumento {
              descripcion
              listaArchivo
              titulo
            }
            listaEnlace
            listaExperienciaLaboral {
              actividad
              cargo
              empresa
              esActual
              fechaDesde
              fechaHasta
            }
            listaInfoIdioma {
              idioma
              profesion
              descripcion
              listaEtiqueta
              mbIndustria{
                _id
                catalogo
              }
            }
            longitud
            nombre
            pais
            peso
            telefono
            tema
            unidadMedidaAltura
            unidadMedidaPeso
            usuario
            verCorreo
            verDireccion
            verAltura
            verCiudad
            verDescripcion
            verEdad
            verPais
            verPeso
            verFoto
            verTelefono
            verWhatsapp
            whatsapp
            zonaHoraria
          }
          llaveFactorAutenticacion
          nombres
          pais
          planSeleccionado
          telefono
          token
          total
          mbTipoCuenta{
            _id
            catalogo
          }
          mbTransaccionSuscripcion {
            _id
            transaccion
          }
          tipoSuscripcion
          zonaHoraria
          tipoMetodoAuthenticate
          usuarioAccesoAuthenticate
          esValidoAuthenticate
          respuestaAuthenticate
          intentoProcesoAuthenticate
        }
      }
    }`;
    const variables = {
      createMbclienteempresaInput,
    };

    return this.http.post(this.apiUrl, { query, variables }, { headers }).pipe(
      map(({ data }: any) => {
        return data.createMbclienteempresa;
      }),
      catchError(({ error }) => {
        return throwError(() => error?.message);
      }),
    );
  }

  obtenerIpCliente(ip: string, token: string): Observable<any> {
    const headers = new HttpHeaders()
      .set('Authorization', 'Bearer ' + token)
      .set('x-api-key', environment.apiKey);
    const query = `query mbclienteObtenerInfoPorip($ip:String!){
      mbclienteObtenerInfoPorIp(ip:$ip){
        infoGeoIp{
          ciudad
          pais
          region
        }
      }
    }`;

    const variables = {
      ip: ip,
    };

    return this.http.post(environment.urlGraphql, { query, variables }, { headers });
  }

  mbclientePorBuscador(valor: string): Observable<any> {
    const headers = new HttpHeaders()
      .set('Authorization', 'Bearer ' + this.token)
      .set('x-api-key', environment.apiKey);
    const query = `query mbclientePorBuscador($valor: String!) {
      mbclientePorBuscador(valor: $valor) {
        _id
        apellidos
        codigo
        correo
        estadoPais
        esValidoAuthenticate
        fechaNacimiento
        idGoogle
        identificacion
        imagen
        mbTipoCuenta {
          _id
        }
        nombres
        pais
      }
    }`;

    const variables = {
      valor,
    };

    return this.http.post(this.apiUrl, { query, variables }, { headers }).pipe(
      map((resp: ApiResponse) => {
        if (resp.errors && !isEmpty(resp.errors)) {
          const error = resp.errors[0].message;
          console.error(error);
          throwError(() => error);
          return null;
        }
        return resp.data;
      }),
      catchError((error) => {
        console.error(error);
        return throwError(() => error);
      }),
    );
  }
}
